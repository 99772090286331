<template>
  <div>
    <div
      style="padding: 20px; text-align: right; display: flex; justify-content: space-between; align-items: center;"
    >
      <div v-if="ucid !== 'default'">
        <span style="margin-right: 10px;">{{ name }}</span>
        <el-button
          type="danger"
          icon="el-icon-delete"
          size="small"
          circle
          @click="handleClickDeleteArtist"
        />
      </div>
      <el-button type="primary" @click="handleAdd">
        添加权益
      </el-button>
    </div>
    <div style="padding: 0 20px;">
      <el-table :data="list" border stripe>
        <el-table-column label="权益" prop="name"></el-table-column>
        <el-table-column label="个人授权" prop="scope">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.scope.personal"
              @change="(val) => handleChangePersonalAuth(val, scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column label="商业授权" prop="scope">
          <template slot-scope="scope">
            <el-switch
              :value="scope.row.scope.business"
              @change="(val) => handleChangeBusinessAuth(val, scope.row)"
            />
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">
              删除
            </el-button>
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="isEdit ? '编辑权益' : '添加权益'"
      :visible.sync="visible"
      width="30%"
      @close="visible = false"
    >
      <div>
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="80px"
          :inline="false"
          size="normal"
        >
          <el-form-item label="权益名称" prop="name">
            <el-input placeholder="请输入权益名称" v-model="form.name" />
          </el-form-item>
          <el-form-item label="个人版权">
            <el-switch v-model="form.scope.personal" />
          </el-form-item>
          <el-form-item label="商业版权">
            <el-switch v-model="form.scope.business" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleClickConfirm">
              {{ isEdit ? "确定修改" : "新增权益" }}
            </el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { nftAuthAdd, nftAuthDelete, nftAuthUpdate, nftAuthDeleteArtist } from "@/api/nft.js";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    ucid: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      form: {
        name: "",
        scope: {
          personal: false,
          business: false,
        },
      },
      rules: {
        name: [{ required: true, message: "请输入权益名称", trigger: "blur" }],
      },
      isEdit: false,
    };
  },
  methods: {
    handleChangePersonalAuth(val, item) {
      this.submitUpdate({
        id: item.id,
        name: item.name,
        scope: {
          personal: val,
          business: item.scope.business,
        },
      });
    },
    handleChangeBusinessAuth(val, item) {
      this.submitUpdate({
        id: item.id,
        name: item.name,
        scope: {
          personal: item.scope.personal,
          business: val,
        },
      });
    },

    handleClickDeleteArtist() {
      this.$confirm(`确认删除艺术家：${this.name}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.submitDeleteArtist({
          artist_ucid: this.ucid,
        });
      });
    },

    handleDelete(data) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.submitDelete({
          id: data.id,
        });
      });
    },

    handleAdd() {
      this.form = {
        name: "",
        scope: {
          personal: false,
          business: false,
        },
      };
      this.visible = true;
      this.isEdit = false;
    },

    handleEdit(data) {
      this.form = {
        name: data.name,
        scope: {
          personal: data.scope.personal,
          business: data.scope.business,
        },
        id: data.id,
      };
      this.visible = true;
      this.isEdit = true;
    },
    handleClose() {
      this.visible = false;
      this.isEdit = false;
    },
    async submitAdd(params) {
      try {
        await nftAuthAdd({
          ...params,
          artist_ucid: this.ucid,
        });
        this.$message.success("新增成功");
        this.fetchList();
        this.handleClose();
      } catch (error) {
        console.log(error);
      }
    },
    async submitDelete(params) {
      try {
        await nftAuthDelete(params);
        this.$message.success("删除成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },

    async submitDeleteArtist(params) {
      try {
        await nftAuthDeleteArtist(params);
        this.$message.success("删除成功");
        this.fetchList();
      } catch (error) {
        console.log(error);
      }
    },

    async submitUpdate(params) {
      try {
        await nftAuthUpdate(params);
        this.$message.success("修改成功");
        this.fetchList();
        this.handleClose();
      } catch (error) {
        console.log(error);
      }
    },

    fetchList() {
      this.$emit("updateList");
    },

    handleClickConfirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            this.submitUpdate({ ...this.form });
          } else {
            this.submitAdd({ ...this.form });
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
