<template>
  <div style="padding: 20px">
    <el-row :gutter="20">
      <el-col :span="4" :offset="0">
        <div class="artist-container">
          <div style="padding: 20px">
            <el-button
              @click="handleClickAddArtist"
              style="width: 100%"
              icon="el-icon-plus"
              type="primary"
              size="default"
            >
              新增艺术家
            </el-button>
          </div>
          <div
            :class="{
              'active-artist-item': item.artist_ucid === currentArtist.artist_ucid,
              'artist-item': true,
            }"
            v-for="item in list"
            :key="item.artist_ucid"
            @click="currentArtist = item"
          >
            <div>{{ item.artist_nickname }}</div>
          </div>
        </div>
      </el-col>
      <el-col :span="20" :offset="0">
        <scope-table
          @updateList="fetchList"
          :list="currentArtist.authoritys"
          :ucid="currentArtist.artist_ucid"
          :name="currentArtist.artist_nickname"
        />
      </el-col>
    </el-row>

    <el-dialog
      title="新增艺术家"
      :visible.sync="addArtistVisible"
      width="30%"
      @close="addArtistVisible = false"
    >
      <div>
        <el-input v-model="addArtistUcid" placeholder="输入艺术家的UCID" size="normal" clearable />
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleConfirmAddArtist" :loading="addArtistLoading">
          确定
        </el-button>
      </span>
    </el-dialog>
    <!-- 
     -->
  </div>
</template>

<script>
import { nftAuthList, addArtistForAuthority } from "@/api/nft.js";
import ScopeTable from "./table/index.vue";

export default {
  components: {
    ScopeTable,
  },
  data() {
    return {
      activeTab: "default",
      list: [],

      currentArtist: {},

      addArtistVisible: false,
      addArtistUcid: "",
      addArtistLoading: false,
    };
  },

  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      try {
        const { data } = await nftAuthList();
        const items = data && data.data && data.data.items;
        if (Array.isArray(items)) {
          this.list = items;
          this.updateCurrentArtist(items);
          // const [defaultArtist, ...restList] = items;
          // this.defaultArtist = defaultArtist;
          // this.openShopArtistList = restList;

          // if (restList[0]) {
          //   this.currentArtist = restList[0];
          // }
        }
        // this.list = items;
      } catch (error) {
        console.log(error);
        // this.defaultArtist = {};
        this.list = [];
      }
    },

    updateCurrentArtist(list) {
      if (this.currentArtist.artist_ucid) {
        const newArtist = list.find((i) => i.artist_ucid == this.currentArtist.artist_ucid);
        if (newArtist) {
          this.currentArtist = newArtist;
        } else {
          this.currentArtist = list[0] || {};
        }
      } else {
        this.currentArtist = list[0] || {};
      }
    },

    handleClickAddArtist() {
      this.addArtistVisible = true;
    },

    async handleConfirmAddArtist() {
      if (this.addArtistLoading) {
        return;
      }

      if (!this.addArtistUcid) {
        this.$message.warning("请输入艺术家UCID");
        return;
      }

      this.addArtistLoading = true;
      try {
        await addArtistForAuthority({ artist_ucid: this.addArtistUcid });
        this.$message.success("新增艺术家成功");
        this.fetchList();
        this.addArtistLoading = false;
        this.addArtistVisible = false;
      } catch (error) {
        this.addArtistLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.artist-item {
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  margin: 8px 0;
  padding: 0 20px;
  font-size: 16px;
  cursor: pointer;

  > div {
    &:hover {
      opacity: 0.8;
      background: #ccc;
    }
  }
}

.active-artist-item {
  > div {
    border-bottom: 1px solid #409eff;
  }
  color: #409eff;
  font-weight: bold;
}

.artist-container {
  height: calc(100vh - 155px);
  overflow-y: scroll;
  background-color: #efefef;
}
</style>
